// deno-lint-ignore-file no-explicit-any

import MaybeSignal, { Unsignalify } from 'api/types/MaybeSignal.ts';

export default function Booleanify(
    value: MaybeSignal<any> | undefined,
): boolean {
    value = Unsignalify(value);

    if (value === undefined) return false;
    if (value === null) return false;

    if (typeof value === 'boolean') return value;

    if (typeof value === 'string') return value !== 'false' && value.length > 0;

    if (typeof value === 'number') return !(isNaN(value) || value == 0);

    if (typeof value === 'object') return Object.keys(value).length > 0;

    if (typeof value == 'function') return Boolean(value());

    return false;
}
